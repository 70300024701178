import { useReactiveVar } from '@apollo/client';
import { previewModelSignKeysVar } from '@/graphql/cache';

type useModelSignKeyType = {
  typename: string;
  id: string;
};

type ModelSignKeyHeadersReturnType = {
  'Model-Sign-Key': [value: string];
};

/*
 * A hook to insert headers for retrieving a specific postset or story that is public.
 */
export default function useModelSignKey({
  typename,
  id,
}: useModelSignKeyType): ModelSignKeyHeadersReturnType | null {
  const keys = useReactiveVar(previewModelSignKeysVar);

  if (keys?.[typename]?.[id]) {
    return {
      'Model-Sign-Key': keys[typename][id], // Can contain the email address
    };
  }

  return null;
}
